import React from 'react';
import './Policy.css';
import Navbar from './Navbar';

function Terms() {
  return (
    <div className="about-us-container container justify-content-center align-items-center">
      <Navbar />
      <div className="about-us-content">
        <h1>Terms of Use</h1>
        <p>These Terms of Use ("Terms") govern your access to and use of the Watchlist.email website ("Watchlist" or "Service"). Please read these Terms carefully before using the Service.</p>
        <ol>
        <li><strong>Acceptance of Terms:</strong> By accessing or using the Watchlist website (watchlist.email) and its related services, you agree to be bound by these Terms of Use.</li>
        <li><strong>Description of Service:</strong> Watchlist provides a web application that assists users in shopping by finding the best prices for items, monitoring for upcoming deals, and sending notifications via email. The service operates solely through the Watchlist website.</li>
        <li><strong>User Conduct:</strong> Users of Watchlist must not engage in any activity that disrupts or interferes with the operation of the service. This includes but is not limited to: attempting to gain unauthorized access to the service, transmitting harmful code or malware, or engaging in any form of abusive behavior towards other users or the Watchlist team.</li>
        <li><strong>Intellectual Property:</strong> All content and materials provided on Watchlist, including but not limited to text, graphics, logos, images, and software, are the property of Watchlist and are protected by intellectual property laws. Users agree not to reproduce, distribute, or modify any content from Watchlist without prior written consent.</li>
        <li><strong>Limitation of Liability:</strong> Watchlist is provided on an "as is" and "as available" basis. We make no warranties, express or implied, regarding the accuracy, reliability, or availability of the service. In no event shall Watchlist be liable for any damages arising from the use or inability to use the service.</li>
        <li><strong>Termination of Service:</strong> Watchlist reserves the right to terminate or suspend access to the service at any time, without prior notice, for any reason including but not limited to user violation of these Terms of Use.</li>
        <li><strong>Privacy:</strong> We respect the privacy of our users. Please refer to our Privacy Policy for information on how we collect, use, and disclose personal information.</li>
        <li><strong>Changes to Terms:</strong> Watchlist may update or modify these Terms of Use from time to time without prior notice. Continued use of the service after any such changes shall constitute acceptance of the modified terms.</li>
        <li><strong>Governing Law:</strong> These Terms of Use shall be governed by and construed in accordance with the laws of California, without regard to its conflict of law provisions.</li>
        <li><strong>Contact Us:</strong> If you have any questions or concerns regarding these Terms of Use, please contact us at {'hello@' + window.location.hostname}.</li>
    </ol>

    <p>By using Watchlist, you acknowledge that you have read, understood, and agree to be bound by these Terms of Use. If you do not agree to these terms, please do not use the service.</p>
      </div>
    </div>
  );
}

export default Terms;
