import React from 'react';
import { ImSpinner6 } from "react-icons/im";

function Spinner() {
  return (
    <div className="spinner">
      <ImSpinner6 className="spinner-icon fs-4" />
    </div>
  );
}

export default Spinner;
