import React from 'react';
import './Policy.css';
import Navbar from './Navbar';

function Privacy() {
  return (
    <div className="about-us-container container justify-content-center align-items-center">
      <Navbar />
      <div className="about-us-content">
        <h1>Privacy Policy</h1>
        <p>At Watchlist, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and disclose information when you use our services:</p>

        <h4>Information We Collect</h4>
        <p>We may collect personal information such as your name and email address when you chat with Watchlist. We may also collect non-personal information such as your IP address and browsing history.</p>

        <h4>How We Use Your Information</h4>
        <p>We use the information we collect to provide and improve our services, communicate with you, and personalize your experience on Watchlist. We do not sell your information to third parties.</p>

        <h4>Disclosure of Information</h4>
        <p>We may disclose your information in response to legal requests or to protect our rights and interests. We may also share aggregated or anonymized data for analytical purposes.</p>

        <h4>Security</h4>
        <p>We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure.</p>

        <h4>Changes to this Privacy Policy</h4>
        <p>We may update or modify this Privacy Policy from time to time. We will notify you of any changes by posting the updated policy on our website.</p>

        <h4>Contact Us</h4>
        <p>If you have any questions or concerns about this Privacy Policy, please contact us at {'hello@' + window.location.hostname}.</p>
      </div>
    </div>
  );
}

export default Privacy;
