import React, { useState, useRef, useEffect } from 'react';
import Icon from './icon.svg';
import './bootstrap.min.css';
import './App.css';
import { FaApple } from "react-icons/fa6";
import { BiSolidDiscount } from "react-icons/bi";
import { IoMdPricetags } from "react-icons/io";
import { PiTargetBold } from "react-icons/pi";
import { FaArrowUp } from "react-icons/fa";
import Spinner from './Spinner'; // Import your spinner component
import { v4 as uuidv4 } from 'uuid';


import Navbar from './Navbar';

function Home() {
  const [isBotProcessing, setIsBotProcessing] = useState(false); // New state for bot processing
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const chatContainerRef = useRef(null);

  useEffect(() => {
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    const initialBotMessage = { text: "How may I help you today?", isUser: false };
    setMessages([initialBotMessage]);

    const sessionId = localStorage.getItem('sessionId');
    if (!sessionId) {
      const newSessionId = uuidv4();
      localStorage.setItem('sessionId', newSessionId);
    }

  }, []);
  
  const getSessionId = () => {
    return localStorage.getItem('sessionId');
  };

  const sendMessage = (text) => {
    if (text.trim() === '') return; // Don't send empty messages

    const userMessage = { text: text, isUser: true };
    const newMessages = [...messages, userMessage];
    setMessages(newMessages);
    setIsBotProcessing(true); // Set bot processing to true when sending message

    const sessionId = getSessionId(); // Get sessionId from localStorage

    fetch('/api/chat', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ message: text, sessionId: sessionId })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        const botMessage = { text: data.answer, isUser: false };
        const updatedMessages = [...newMessages, botMessage];
        setMessages(updatedMessages);
      })
      .catch(error => {
        console.error('Error:', error);
        // Handle error if needed
      })
      .finally(() => {
        setIsBotProcessing(false); // Set bot processing to false when response received
      });
  };

  const handleTipClick = (tipText) => {
    sendMessage(tipText);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      sendMessage(inputText);
      setInputText('');
    }
  };


  return (
    <div className="container justify-content-center align-items-center">
      <Navbar />
      <div className="icon-container">
        <img src={Icon} alt="Icon" className="logo" />
      </div>

      <div className="tips mx-auto mb-3">

          <div className="row p-2">
            <div className="tip col border p-3 rounded custom-shadow" role="button" onClick={() => handleTipClick("Watch for brands")}>
              <div className="tip-text"><span className='fs-3 mx-2'><FaApple /></span>Watch brands</div>
              <div>notify me for deals & discount</div>
            </div>
            <div className="tip col border p-3 rounded custom-shadow" role="button" onClick={() => handleTipClick("Watch products")}>
              <div className="tip-text"><span className='fs-3 mx-2'><PiTargetBold /></span>Watch products</div>
              <div>notify me if price drop</div>
            </div>
          </div>

          <div className="row p-2">
            <div className="tip col border p-3 rounded custom-shadow" role="button" onClick={() => handleTipClick("Watch deals")}>
              <div className="tip-text"><span className='fs-3 mx-2'><BiSolidDiscount /></span>Watch deals</div>
              <div>keep an eye on upcoming deals</div>
            </div>
            <div className="tip col border p-3 rounded custom-shadow" role="button" onClick={() => handleTipClick("Find best price")}>
              <div className="tip-text"><span className='fs-3 mx-2'><IoMdPricetags /></span>Find best price</div>
              <div>best price ever historically</div>
            </div>
          </div>

      </div>


      <div className="chat-container mb-2" ref={chatContainerRef}>
        <div className="chat-box">
          {messages.map((msg, index) => (
            <div
              key={index}
              className={`chat-bubble ${msg.isUser ? 'user' : 'bot'}`}
            >
              {msg.text}
            </div>
          ))}
          {isBotProcessing && <Spinner />} {/* Render spinner if bot is processing */}
        </div>
        <div className="input-container">
          <div className="input-group">
            <input
              type="text"
              className="form-control rounded-start" // Add Bootstrap rounded-start class
              placeholder="Type your message..."
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <button className="btn btn-dark rounded-end px-4" onClick={() => {sendMessage(inputText); setInputText('');}}>
              <FaArrowUp />
            </button>
          </div>
        </div>

      </div>

      {/* Footer */}
      <div className="footer d-flex justify-content-center mt-5">
        Made with <span role="img" aria-label="Love">❤️</span> by E Home Inc
      </div>

    </div>
  );
}

export default Home;