import './Navbar.css';
import './bootstrap.min.css';
import { MdEmail } from "react-icons/md";
import { FaHome } from "react-icons/fa";
import { HiClipboardDocumentList } from "react-icons/hi2";
import { MdPrivacyTip } from "react-icons/md";

import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { AiOutlineGlobal } from "react-icons/ai";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleOverlayClick = () => {
    setIsOpen(false);
  };

  return (
    <nav className="navbar">
      <div className="navbar-toggle" onClick={toggleMenu}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </div>
      {isOpen && <div className="navbar-overlay" onClick={handleOverlayClick}></div>}
      <ul className={`navbar-menu ${isOpen ? 'active' : ''}`}>
        <li className="navbar-item close-icon" onClick={toggleMenu}>
          <FaTimes />
        </li>
        <li className="navbar-item"><a href="/" className='text-decoration-none text-dark'><h4>WATCHLIST.EMAIL</h4></a></li>
        <li className="navbar-item"><FaHome /> <a href="/" className='text-decoration-none text-dark'>Home</a></li>
        <li className="navbar-item"><HiClipboardDocumentList /> <a href="/terms" className='text-decoration-none text-dark'>Terms of Use</a></li>
        <li className="navbar-item"><MdPrivacyTip /> <a href="/privacy" className='text-decoration-none text-dark'>Privacy Policy</a></li>
        <li className="navbar-item"><MdEmail /> {'hello@' + window.location.hostname}</li>
        <li className="navbar-item"><AiOutlineGlobal /> Compatible with all brands, products, and online stores!</li>
        <li className="navbar-item">A smart and powerful newsletter application that saves you money and time. We've done the homework for you behind the scenes and send you the best results. Simplify your newsletter management effortlessly with organized and user-friendly features. Get tasks done seamlessly, just like a breeze.</li>
      </ul>
    </nav>
  );
}

export default Navbar;
